<template>
  <v-app dark>

    <!-- Left menu  -->
    <v-navigation-drawer
      app
      v-model="sideNav"
      clipped
      disable-resize-watcher
      temporary
      >
      <v-list v-if="authenticated">
        <v-list-tile>
          <v-list-tile-action>
            <v-icon>person</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            {{ currentuser }}
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile @click.prevent="logoutUser">
          <v-list-tile-action>
            <v-icon>lock_outline</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Log uit</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click.prevent="about">
          <v-list-tile-action>
            <v-icon>info_outline</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>About</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

    <!-- Upper menu/ toolbar -->
    <v-toolbar dark class="primary">

      <v-toolbar-side-icon
        v-if="authenticated"
        @click.native.stop="sideNav = !sideNav"
        class="hidden-sm-and-up">
      </v-toolbar-side-icon>
      <v-toolbar-title>Maan-standen</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items
        class="hidden-xs-only"
        v-if="authenticated">
        <v-btn
          flat
          @click.prevent="logoutUser">
            <v-icon left dark>person</v-icon>
            {{ currentuser }} uitloggen
        </v-btn>
        <v-btn
          flat
          @click.prevent="about">
          <v-icon dark>info_outline</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!-- Actual content -->
    <main>
      <router-view></router-view>
    </main>
  </v-app>
</template>


<script>
  export default {
    data () {
      return {
        sideNav: false
      }
    },

    computed: {
      authenticated () {
        return this.$store.getters.authenticated
      },
      currentuser () {
        return this.$store.getters.currentuser
      }
    },

    created() {
      // Check if the is a user known in localStorage
      // In that case the user may be logged in.
      if (localStorage.currentuser !== undefined) { //if01
        this.$store.commit('currentuser', localStorage.currentuser);
        if (localStorage.sessionid !== undefined) { //if02
          this.$store.commit('sessionid', localStorage.sessionid);
          // When a sessionID is available, see if it still is active
          this.$store.dispatch('checkUserSession');
        } else { //if02
          // Geen sessie in local localStorage gevonden //
        }
      }  //if02
      else {  //if01
        // Geen gebruiker in local localStorage gevonden //
      }  //if01
    },

    methods: {
      logoutUser () {
        this.$store.dispatch('logoutUser');
        this.$router.push('/login');
      },
      about () {
        this.$router.push('/about');
      }
    }
  }
</script>
<!--
<style lang="stylus">
  @import './stylus/main'
</style> -->
