import Vue from 'vue'
import vueRouter from 'vue-router'
import login from '../components/login.vue'
import about from '../components/about.vue'
import datelist from '../components/datelist.vue'
import authGuard from './auth-guard'

Vue.use(vueRouter)

export const router = new vueRouter({
  routes: [
    {
      path: '/',
      name: 'datelist',
      component: datelist,
      beforeEnter: authGuard
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/about',
      name: 'about',
      component: about,
      beforeEnter: authGuard
    }
  ],
  mode: 'history'
})
