import Vue from 'vue'
import '../../node_modules/vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'
//import '../../stylus/main.styl'
import { router } from '../router'
import { store } from '../store'
import DateFullFormat from '../filters/date'

import App from '../components/app.vue'
import DateSaveDialog from '../components/saveDialog.vue'
import DateRevertDialog from '../components/revertDialog.vue'

Vue.use(Vuetify, {
  theme: {
    primary: '#A23070',
    // secondary: '#b0bec5',
    // accent: '#8c9eff',
    // error: '#b71c1c',
    action_button: '#3669FF',   //3B82FF
    deviationColor0: '69D900',
    deviationColor1: '9DD900',
    deviationColor2: 'FFB100',
    deviationColor3: 'FF5D00',
    deviationColor4: 'DF2000',
    deviationColorNA: '696969'
    }
  }
);
Vue.config.productionTip = false;

Vue.component('app-date-save-dialog', DateSaveDialog)
Vue.component('app-date-revert-dialog', DateRevertDialog)

Vue.filter('DatumOpmaakVolUit', DateFullFormat)

new Vue({
  el: 'app',
  router: router,
  store: store,
  render: h => h(App)
});
