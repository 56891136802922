// Login component //

<template>
  <div id="login">
    <v-container dark fluid grid-list-lg>

      <v-layout v-if="autologout" align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="secondary">
              <v-toolbar-title align-center justify-center>
                  Je bent automatisch uitgelogd.
              </v-toolbar-title>
            </v-toolbar>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary" class="elevation-0">
              <v-toolbar-title>Inloggen</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form @submit.prevent="login">
                <v-text-field v-model="userName" prepend-icon="person" name="userName" label="Inlognaam" type="text" required></v-text-field>
                <v-text-field v-model="password" prepend-icon="lock" name="password" label="Wachtwoord" id="password" type="password" required></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="action_button" @click="login">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

  </div>
</template>


// oud
<!-- <template>
  <div id="login">
    <v-container>
      <v-layout>
        <v-flex v-if="autologout" xs12 sm6 offset-sm3 class="text-xs-center" height="100px">
          Je bent automatisch uitgelogd.
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm6 offset-sm3>
          <v-card>
            <v-card-text>
              <v-container>
                <form @submit.prevent="login">
                  <v-layout row>
                    <v-flex xs12>
                      <v-text-field
                        name="userName"
                        label="Inlognaam"
                        id="email"
                        v-model="userName"
                        required>
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs12>
                      <v-text-field
                        name="password"
                        label="Wachtwoord"
                        id="password"
                        type="password"
                        v-model="password"
                        required>
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs12>
                      <v-btn type="submit">Login</v-btn>
                </v-flex>
                  </v-layout>
                </form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template> -->

<script>
export default {
  name: 'login',

  data () {
    return { // No need to make this available outside this component
      userName: '',
      password: ''
    }
  },

  computed: {
    authenticated () {
      return this.$store.getters.authenticated
    },
    autologout () {
      return this.$store.getters.autologout
    }
  },

  watch: {
    // when login succeeds then automatically go to main page
    authenticated (value) {
      if (value) {
        this.$router.push('/');
      }
    }
  },

  methods: {
    login () {
      // console.log( this.userName.toLowerCase, this.password);
      this.$store.dispatch('loginUser',{userName: this.userName.toLowerCase(), password: this.password})
    }
  }
}
</script>
