import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    appName: 'maan',
    appVersion: '0.1.0',
    appEnv: 'Development',
    authEnv: '',
    serviceEnv: '',
    currentuser: '',
    sessionid: '',
    authenticated: false,
    autologout: false,
    datums: [],
    openDate: ''
  },

  getters: {
    appName(state) {
      return state.appName;
    },
    appVersion(state) {
      return state.appVersion;
    },
    appEnv(state) {
      return state.appEnv;
    },
    authEnv(state) {
      return state.authEnv;
    },
    serviceEnv(state) {
      return state.serviceEnv;
    },
    currentuser(state) {
      return state.currentuser;
    },
    sessionid(state) {
      return state.sessionid;
    },
    authenticated(state) {
      return state.authenticated;
    },
    autologout(state) {
      return state.autologout;
    },
    datums(state) {
      return state.datums;
    },
    openDate(state) {
      return state.openDate;
    }
  },

  mutations: {
    currentuser(state, userName) {
      state.currentuser = userName;
    },
    sessionid(state, sessionID) {
      state.sessionid = sessionID;
    },
    authenticated(state, authenticated) {
      state.authenticated = authenticated;
    },
    autologout(state, autologout) {
      state.autologout = autologout;
    },
    authEnv(state, authEnv) {
      state.authEnv = authEnv;
    },
    serviceEnv(state, serviceEnv) {
      state.serviceEnv = serviceEnv;
    }
  },

  actions: {
    loginUser ({commit}, payload) {
      var params = new URLSearchParams();
      params.append('f', 'login');
      params.append('app', this.state.appName);
      params.append('user', payload.userName);
      params.append('pw', payload.password);
      axios.post('/api/auth', params)
      .then(
        response => {
          console.log(response.data);
          if (response.data.succesful) {
            commit('authEnv', response.data.env);
            commit('sessionid', response.data.sessionid);
            commit('currentuser', payload.userName);
            commit('authenticated', true);
            commit('autologout', false);
            localStorage.sessionid = response.data.sessionid;
            localStorage.currentuser = payload.userName
          } else {
            commit('authEnv', response.data.env);
            commit('autologout', false);
            alert(response.data.msg);
          }
        }
      )
      .catch(
        // dit is bij een technische fout
        error => {
          console.log(error);
        }
      )
    },

    logoutUser ({commit}, payload) {
      var params = new URLSearchParams();
      params.append('f', 'logout');
      params.append('session', this.state.sessionid);
      axios.post('/api/auth', params)
      .then(response => {
        console.log(response.data);
        commit('authEnv', response.data.env);
        commit('sessionid', '');
        commit('currentuser', '');
        commit('authenticated', false);
        commit('autologout', false);
        localStorage.removeItem("currentuser");
        localStorage.removeItem("sessionid");
      })
      .catch(
        // dit is bij een technische fout
        error => {
          console.log(error);
        }
      )
    },

    checkUserSession ({commit}, payload) {
      var params = new URLSearchParams();
      params.append('f', 'checksession');
      params.append('app', this.state.appName);
      params.append('session', this.state.sessionid);
      params.append('user', this.state.currentuser);
      axios.post('/api/auth', params)
      .then(response => {
        console.log(response.data);
        if (response.data.succesful) {
          commit('authEnv', response.data.env);
          commit('authenticated', true);
        } else {
          // The session was no longer valid, so auto logout occurred
          commit('autologout', true);
          // Remove the login and session data in the store (just to be sure)
          commit('authEnv', response.data.env);
          commit('sessionid', '');
          commit('currentuser', '');
          commit('authenticated', false);
          // Remove the login and session data, next time you open the app autologout is false
          localStorage.removeItem("currentuser");
          localStorage.removeItem("sessionid");
        }
      })
      .catch(
        // dit is bij een technische fout
        error => {
          console.log(error);
        }
      )
    },

    initializeDatums  ({commit}, payload) {
      this.state.datums = payload.datums;
      for (let item of this.state.datums) {
        if (item.status == "Open") {
          this.state.openDate = item.datum;
          break;
        }
      };
      this.dispatch('toevoegenVerwachteDatums', {baseDate: this.state.openDate});

      var newDatums = [];
      for (let item of this.state.datums) {
          var newDatum = {
            "status": item.status,
            "datum": item.datum,
            "aantaldagen": parseInt(item.aantaldagen || '28'), // When no data use 28
            "kleurLabel": 'deviationColor' + Math.min(Math.abs(28-(item.aantaldagen || 28)),4)
          };
          // Correction: When 'Voorlopig' then keep it grey, there is no actual deviation yet
          if (newDatum.status == 'Verwacht') {
            newDatum.kleurLabel = 'deviationColorNA';
          };
          newDatums.push(newDatum);
      }
      // Replace the existing Datums with the newDatums
      this.state.datums = newDatums;
    },

    toevoegenVerwachteDatums ({commit}, payload) {
      var result = new Date(payload.baseDate);
      //var i;
      for (let i = 1; i < 4 ; i++) {
        result.setDate(result.getDate() + 28);
        var lNieuweDatum = {
              "datum": result.getFullYear() + '-' + ('0' + (result.getMonth()+1)).slice(-2) + '-' + ('0' + result.getDate()).slice(-2),
              "aantaldagen": "28",
              "status": "Verwacht",
              "kleurLabel": "deviationColorNA"
        };
        // lNieuweDatum.datum = result.getFullYear() + '-' + ('0' + (result.getMonth()+1)).slice(-2) + '-' + ('0' + result.getDate()).slice(-2);
        this.state.datums.unshift(lNieuweDatum);
      }
    },

    wijzigOpenDate ({commit}, payload) {
      var ldatDatum = new Date(this.state.datums[payload.id].datum);
      ldatDatum.setDate(ldatDatum.getDate() + payload.delta);
      this.state.datums[payload.id].datum = ldatDatum.getFullYear() + '-' + ('0' + (ldatDatum.getMonth()+1)).slice(-2) + '-' + ('0' + ldatDatum.getDate()).slice(-2);
      this.state.openDate = this.state.datums[payload.id].datum;
      this.state.datums[payload.id].aantaldagen = (parseInt(this.state.datums[payload.id].aantaldagen) + payload.delta).toString();
      this.state.datums[payload.id].kleurLabel = 'deviationColor' + Math.min(Math.abs(28-parseInt(this.state.datums[payload.id].aantaldagen)),4);
    },

    datumOpslaan ({commit}, payload) {
      var lstrDateSave = '';
      var params = new URLSearchParams();

      // Verwijder de elementen met status 'Verwacht'
      this.state.datums = this.state.datums.filter(s => s.status !== 'Verwacht');

      // Aanpassen rij met status 'Voorlopig' naar 'Definitief'
      for (let item of this.state.datums) {
        if (item.status == "Voorlopig") {
          item.status = "Definitief";
          break;
        }
      };

      // Aanpassen rij met status 'Open' naar 'Voorlopig'
      // Tevens vastleggen van de datum die opgeslagen moet worden
      for (let item of this.state.datums) {
        if (item.status == "Open") {
          item.status = "Voorlopig";
          item.datum = this.state.openDate;
          lstrDateSave = this.state.openDate;
          break;
        }
      };

      // Voeg een nieuw element toe met status 'Open'
      // Werk ook de openDate bij
      var result = new Date(this.state.openDate);
      result.setDate(result.getDate() + 28);
      this.state.openDate = result.getFullYear() + '-' + ('0' + (result.getMonth()+1)).slice(-2) + '-' + ('0' + result.getDate()).slice(-2);
      var lNieuweDatum = {
      	      "datum": this.state.openDate,
      	      "aantaldagen": "28",
      	      "status": "Open",
              "kleurLabel": "deviationColor0"
      };
      this.state.datums.unshift(lNieuweDatum);

      // Voeg de verwachte rijen weer toe obv de nieuwe 'Open' datum
      this.dispatch('toevoegenVerwachteDatums', {baseDate: this.state.openDate});

      // Verwerk het opslaan in de database
      params.append('f', 'save');
      params.append('currentuser', this.state.currentuser);
      params.append('datumwerkelijk', lstrDateSave);
      axios.post('/api/data', params)
      .then(response => {
        console.log(response.data);
        // Indien dat niet goed gaat, geef dan een melding en draai het scherm terug
        // if (response.data.succesful) then
      });
    },

    datumTerugdraaien ({commit}, payload) {
      var lstrDatumTemp = '';
      var lintAantalDagen;

      // Sla de datum van de voorlopige rij op
      for (let item of this.state.datums) {
        if (item.status == "Voorlopig") {
          lstrDatumTemp = item.datum;
          lintAantalDagen = item.aantaldagen;
          break;
        }
      };

      // bewaar alleen de datums met status 'Definitief'
      this.state.datums = this.state.datums.filter(s => s.status == 'Definitief');

      // Voeg een nieuw element toe met status 'Open' en de datum van de vorige 'voorlopig' rij
      var lNieuweDatum = {
      	    "datum": lstrDatumTemp,
      	    "aantaldagen": lintAantalDagen,
      	    "status": "Open",
            "kleurLabel": 'deviationColor' + Math.min(Math.abs(28-(lintAantalDagen || 28)),4)
      };
      this.state.datums.unshift(lNieuweDatum);

      // Voeg de verwachte rijen weer toe obv de nieuwe 'Open' datum
      this.dispatch('toevoegenVerwachteDatums', {baseDate: lstrDatumTemp});

      // Voer het terugdraaien uit in de database
      var params = new URLSearchParams();
      params.append('f', 'revert');
      params.append('currentuser', this.state.currentuser);
      axios.post('/api/data', params)
      .then(response => {
        console.log(response.data)
      })
    }

  }
})
