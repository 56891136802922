// Login component //
<template>
  <div id="about">
    <v-container>
      <v-layout>
        <v-flex xs12 sm6 offset-sm3>
          <v-card class="elevation-12">

            <v-toolbar dark color="primary" class="elevation-0">
              <v-toolbar-title>About</v-toolbar-title>
            </v-toolbar>
            <v-container fluid>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>App naam</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ appName }}</v-card-text></v-card></v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>App versie</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ appVersion }}</v-card-text></v-card></v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>App omgeving</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ appEnv }}</v-card-text></v-card></v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>Autorisatie omgeving</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ authEnv }}</v-card-text></v-card></v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>Service omgeving</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ serviceEnv }}</v-card-text></v-card></v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>Ingelogde gebruiker</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ currentuser }}</v-card-text></v-card></v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>Sessie ID</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ sessionid }}</v-card-text></v-card></v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout row>
                  <v-flex style="width: 40%"><v-card tile flat><v-card-text>Authenticated</v-card-text></v-card></v-flex>
                  <v-flex style="width: 60%"><v-card tile flat><v-card-text>{{ authenticated }}</v-card-text></v-card></v-flex>
              </v-layout>

              <v-layout row>
                  <v-flex align-content-center>
                      <v-btn color="action_button" to="/">Sluiten</v-btn>
                  </v-flex>
              </v-layout>

            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

export default {
  name: 'about',

  computed: mapGetters({
    appName: 'appName',
    appVersion: 'appVersion',
    appEnv: 'appEnv',
    authEnv: 'authEnv',
    serviceEnv: 'serviceEnv',
    currentuser: 'currentuser',
    sessionid: 'sessionid',
    authenticated: 'authenticated'
  }),

  // watch: {
  //   // Go to public part when not authenticated anymore
  //   authenticated (value) {
  //     if (!value) {
  //       this.$router.push('/login');
  //     }
  //   }
  // }

}
</script>
