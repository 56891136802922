<template>
  <v-dialog v-model="revertDialog" persistent max-width="290">
    <v-btn small fab color="action_button" dark slot="activator"><v-icon dark>undo</v-icon></v-btn>
      <v-card>
          <v-card-title class="headline">Terugdraaien</v-card-title>
      <v-card-text>Weet je zeker dat je deze datum wilt terugdraaien?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" @click="onRevertDate">Ja</v-btn>
        <v-btn color="red darken-1" @click="revertDialog = false">Nee</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        revertDialog: false
      }
    },
    methods: {
      onRevertDate () {
        this.revertDialog = false;
        this.$store.dispatch('datumTerugdraaien');
      }
    }
  }
</script>
