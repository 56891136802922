<template>
  <v-dialog v-model="saveDialog" persistent max-width="290">
    <v-btn small fab color="action_button" dark slot="activator"><v-icon dark>save</v-icon></v-btn>
    <v-card>
      <v-card-title class="headline">
        Opslaan
      </v-card-title>
      <v-card-text>
        Weet je zeker dat je deze datum wilt opslaan?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" @click="onSaveDate">Ja</v-btn>
        <v-btn color="red darken-1" @click="saveDialog = false">Nee</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        saveDialog: false
      }
    },
    methods: {
      onSaveDate () {
        this.saveDialog = false;
        this.$store.dispatch('datumOpslaan');
      }
    }
  }
</script>
