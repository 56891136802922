// Datelist component //
<template>
  <div id="datumlijst">
    <v-container
      fluid
      style="margin: 0px"
      >
      <v-layout justify-center>
        <v-flex xs12 sm8 md5>
          <v-card tile class="white--text elevation-12">
            <v-container fluid style="padding: 0px">
              <template v-for="(datum, index) in datums">
                <v-layout row style="width: 100%">
                  <v-flex style="width: 10%" :class="datum.kleurLabel">
                    <v-card tile flat :class="datum.kleurLabel">
                      <v-card-text style="text-align: center; padding: 16px 0px 0px 0px">
                        {{ datum.aantaldagen }}
                      </v-card-text>
                    </v-card>
                  </v-flex>


                  <v-flex style="width: 8%">
                    <!-- <v-card tile flat>
                      <v-card-text v-if="datum.status=='Open'" tile flat>
                        <button v-on:click='DatumVroeger(index)' flat><v-icon large dark color="action_button">chevron_left</v-icon></button>
                      </v-card-text>
                    </v-card> -->

                    <v-card tile flat>
                      <!-- <v-card-text  tile flat> -->
                        <button v-if="datum.status=='Open'" v-on:click='DatumVroeger(index)' flat><v-icon large dark color="action_button">chevron_left</v-icon></button>
                      <!-- </v-card-text> -->
                    </v-card>

                  </v-flex>
                  <v-flex justify-start style="width: 60%">
                    <v-card tile flat>
                      <v-card-text align-content-center justify-center>
                      {{ datum.datum | DatumOpmaakVolUit  }}
                    </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex style="width: 8%">
                    <!-- <v-card tile flat>
                      <v-card-text v-if="datum.status=='Open'" tile flat>
                        <button v-on:click='DatumLater(index)'><v-icon large dark color="action_button">chevron_right</v-icon></button>
                      </v-card-text>
                    </v-card>
                     -->
                    <v-card tile flat>
                      <!-- <v-card-text v-if="datum.status=='Open'" tile flat> -->
                        <button v-if="datum.status=='Open'"v-on:click='DatumLater(index)'><v-icon large dark color="action_button">chevron_right</v-icon></button>
                      <!-- </v-card-text> -->
                    </v-card>

                  </v-flex>
                  <v-flex style="width: 14%">
                    <v-card tile flat>
                      <div v-if="datum.status=='Open'">
                        <app-date-save-dialog></app-date-save-dialog>
                      </div>
                      <div v-else-if="datum.status=='Voorlopig'">
                        <app-date-revert-dialog></app-date-revert-dialog>
                      </div>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </template>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>


<script>
  import axios from 'axios';

export default {
  name: 'datelist',

  computed: {
    datums () {
      return this.$store.getters.datums
    }
  },

  mounted() {
    // zie aantekening: Verplaats het inlezen van data van mounted naar store (wel aanroepen uit mounted)
    var params = new URLSearchParams();
    params.append('f', 'getdatelist');
    axios.post('/api/data', params)
    .then(response => {
      this.$store.commit('serviceEnv', response.data.env);
      this.$store.dispatch('initializeDatums', {datums: response.data.datums});
    });
  },

  methods: {
    DatumVroeger: function(lintIndex) {
      this.$store.dispatch('wijzigOpenDate', {id: lintIndex, delta: -1});
    },

    DatumLater: function(lintIndex) {
      this.$store.dispatch('wijzigOpenDate', {id: lintIndex, delta: 1});
    },
  }
}
</script>
